<template>
    <public_header title="事件簿" right_title="新增事件" jump_url="/affair_edit"></public_header>
    <div class="main margin-90 ">
        <div class="out-container" @scroll="handleScroll($event)" ref="scroll">
            <div class="schedule-box p10 mt10" v-for="(item,index) in list">
                <div class="schedule-title">{{item.title}}</div>
                <div class="between mt10">
                    <div class="color-black font12">提醒日期：<text class="color-red">{{item.date_type==1 ? item.remind_date:select_day(item.remind_date)['IDateCn']}}</text></div>
                    <div class="color-black font12">提醒时间：<text class="color-red">{{item.remind_time}}</text></div>
                </div>
                <div class="color-black font12">日期类型：{{item.date_type==1?'阳历':'农历'}}</div>
                <div class="color-black font12">重复提醒：{{item.schedule_type==1?'否':'是'}}</div>
                <div class="color-gray font12">事件描述：{{item.describe}}</div>
                <div class="color-gray font12">创建时间：{{item.c_time}}</div>
                <div class="between mt10">
                    <van-button plain hairline type="danger" size="mini" @click="remove_data(item.idex)">删除</van-button>
                    <van-button plain hairline type="warning" size="mini" @click="$router.push(`/affair_edit?id=${item.idex}`)">编辑</van-button>
                </div>
            </div>
            <div class="text-center font14" style="font-weight: bold" v-if="this.list.length==0">暂无数据~</div>
        </div>

    </div>
</template>

<script>
  import Public_header from "@c/header";
  import {schedule_list,del_schedule} from "@net/blotter";
  import calendar from '@js/calendar'
  import {select_day} from "@u/custom";

  let timer = null
  export default {
    name: "blotter_list",
    components: {Public_header},
    data() {
      return {
        list: [],
        page: 1,
        scroll_state: true
      }

    },

    methods: {
      /*select_day(day) {
        let solarDayArr = day.split('-');
        let lunarDay = calendar.solar2lunar(solarDayArr[0], solarDayArr[1], solarDayArr[2]);
        let festAndTerm = [];
        festAndTerm.push(lunarDay.festival == null ? '' : ' ' + lunarDay.festival);
        festAndTerm.push(lunarDay.lunarFestival == null ? '' : '' + lunarDay.lunarFestival);
        festAndTerm.push(lunarDay.Term == null ? '' : '' + lunarDay.Term);
        festAndTerm = festAndTerm.join('');
        let l_date = lunarDay.IMonthCn + lunarDay.IDayCn;
        if (festAndTerm != '') {
          this.title = l_date + ' • ' + festAndTerm;
        } else {
          this.title = l_date;
        }
        return l_date;
      },*/
      select_day,
      get_data() {
        let obj = {page: this.page};
        obj.is_login = 1;
        schedule_list(obj).then(res => {
          if (res.business_code == 200) {
            if (res.data.length == 0) {
              this.scroll_state = false
            } else {
              this.page++
              this.list.push(...res.data)
            }

          }
        });
      },
      refresh(){
        this.page=1
        this.list.length=0
        this.get_data()
      },
      handleScroll() {
        if (!this.scroll_state) {
          return
        }
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          if (this.$refs.scroll.scrollTop + 1 >= this.$refs.scroll.scrollHeight - this.$refs.scroll.clientHeight) {
            this.get_data()
          }
          clearTimeout(timer)
        }, 500)

      },
      remove_data(id){
        let obj={id,is_login:1}
        if(timer){
          clearTimeout(timer)
        }
        timer=setTimeout(()=>{
          del_schedule(obj).then(res=>{
            if(res.business_code==200){
              this.$toast('操作成功')
              this.refresh()
            }else {
              this.$toast('操作失败')
            }
          })
          clearTimeout(timer)
        },800)

      }
    },
    created() {
      if (!this.Utils.has_login()) {
        this.Utils.go_login(this.$route.path, this.$route.query)
        return
      }
    },
    mounted() {
      this.get_data()
    },
    beforeCreate () {//设置样式

      document.querySelector('body').setAttribute('style', 'background:#f4f4f4');
    },
    beforeUnmount () {//移除样式
      document.querySelector('body').removeAttribute('style')
    }
  }
</script>

<style scoped>
    .schedule-box {
        background-color: white;
    }

    .schedule-title {
        font-size: 15px;
        color: #333;
        font-weight: bold;
    }
</style>